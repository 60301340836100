import { FC } from "react";
import { Box, Paper, Typography } from "@mui/material";
import indexNavItems from "@/config/index-nav";
import { useNavigate } from "react-router";
import { PartiallyEmittedExpression } from "typescript";
import CoreBox from "@/common/components/widgets/core-box";


const NavItem: FC<Partial<IndexNavItem>> = (item) => {
    return <div className="flex flex-col justify-center p-2 items-center">
        <div className="w-[2rem] h-[2rem] mx-2  rounded-circle overflow-hidden">
            <img src={item.src} alt={item.label} className="w-full h-full" />
        </div>
        <div className="text text-[.7rem] p-[.3rem]">
            <Typography variant="body2" component={"p"} color="text.primary" className="text-[.7rem]">
                {item.label}
            </Typography>
        </div>
    </div>
}

const HomeNav: FC = () => {
    const itemList = indexNavItems;
    const navigate = useNavigate();
    const handleClickNav = (item: Partial<IndexNavItem>) => {
        if (item?.path) {
            navigate(item?.path);
        }
    }
    const handleToSign = () => {
        navigate("/sign");
    }
    return <div>
        <CoreBox className="rounded-t-lg">
            <Box className="rounded-md  w-full mt-2">
                <div className="overflow-x-scroll disable-scrollbar">
                    <div className="flex flex-wrap max-h-[12rem] flex-col ">
                        {
                            itemList.map((_, ndx) => <div key={ndx} onClick={() => handleClickNav(_)}>
                                <NavItem key={ndx} {..._} />
                            </div>)
                        }
                    </div>
                </div>
            </Box>
        </CoreBox>
        <div className="rounded-b-lg overflow-hidden w-full" onClick={() => { handleToSign()}}>
            <img className="w-full" src="https://new.ivypha.com/static/uploads/2023/3/24//bbea45e953bd40377fd314bca2567c29.gif" alt="sing" />
        </div>
    </div>
}

export default HomeNav;